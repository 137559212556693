import React from "react";


const AdminHome = () => {
    return (
        <>
        <div className="tw-mt-6">
            CloudSafari Admin Team Dashboard
        </div>
        <div className="tw-mt-3">
            Edit here.
        </div>
        </>
    )
};

export default AdminHome;